
@import url("../../CSS/Theme.css");

.snowman{
    display:flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding-top:85px;

}

.image__container{
    width: 40%;
    display: flex;
    max-width: 400px;
}


.snowman__game__container{
    width:100%;
    min-width: 350px;
    max-width: 800px;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
}


.snowman__top__info__view{
    width: 30%;
    display: flex;
    min-width: 600px;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;

}

.game__info__container{
    width: 30%;
    min-width:600px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    background-color: var(--dark__main);
    border-radius:10px;
}

.opponent__info__container{
    padding:5px;
    display: flex;
    align-content: center;
    align-items: center;
    vertical-align: middle;
    width: auto;
    flex-direction: row;
}


.profile__image {
    width:20%;
    height:auto;
    aspect-ratio:1/1;
    border-radius: 50%;
    overflow: hidden;
}

.handle__text{
    margin-left: 16px;
    color: gray;
}


.vertical__auto{
    display: flex;
    background-color: var(--dark__main);
    border-radius: 10px;
    flex-direction: column;
    height: auto;
    width: 50%;
    margin:2px;
    padding:10px;
}

.letter__guessed{
  background: gray;
  margin: 5px;
  width: 35px;
  height: 55px;
  display: inline-block;
  border-radius: 4px;
  box-shadow: -2px 2px 2px #343738;
}

.letter__not__guessed{
  background: white;
  margin: 5px;
  width: 35px;
  height: 55px;
  display: inline-block;
  background-color: white;
  border-radius: 4px;
  box-shadow: -2px 2px 2px #343738;

}
.blank__underline{
  text-decoration: underline;
  color:white;
  height:40px;
  font-weight: bold;
  font-size: 28px;
  margin-left: 5px;
  margin-right: 5px;
}
.keyboard__grid{
  background-color: var(--dark__main);
  width: 405px;
  padding: 5px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.word__container{
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 550px;
  justify-content: center;
  display: flex;
  flex-direction: row;

}

@media only screen and (max-width: 768px) {

    .snowman{
        padding-top:55px;
        display:flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }


    .snowman__game__container{
        width:96%;
        display: flex;
        flex-direction: column;
    }


    .snowman__top__info__view{
        width: 94%;
        display: flex;
        min-width: 94%;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: space-evenly;
    }


    .opponent__info__container{
        padding:5px;
        display: flex;
        align-content: center;
        align-items: center;
        vertical-align: middle;
        width: auto;
        flex-direction: row;
    }


    .profile__image{
        width:10%;
        height:auto;
        aspect-ratio:1/1;
        border-radius: 50%;
        overflow: hidden;
    }


    .handle__text{
        margin-left: 10px;
        color: gray;
    }


    .game__info__container{
        width: 94%;
        min-width: 94%;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        background-color: var(--dark__main);
        border-radius:10px;

    }

    .vertical__auto{
        background-color: var(--dark__main);
        border-radius: 10px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        padding:5px;
        height: auto;
        width: 50%;
    }


    .letter__guessed{
      background: gray;
      margin: 2.5px;
      width: 17;
      height: 50px;
      display: inline-block;
      padding:0px;
      border-radius: 4px;
      box-shadow: -2px 2px 2px #343738;
    }

    .letter__not__guessed{
      background: white;
      margin: 2.5px;
      width: 17;
      height: 50px;
      padding:0px;
      display: inline-block;
      background-color: white;
      border-radius: 4px;
      box-shadow: -2px 2px 2px #343738;

    }
    .word__container{
      margin-top: 2px;
      margin-bottom: 2px;
      width: 100%;
      max-width: 550px;
      justify-content: center;
      display: flex;
      flex-direction: row;

    }
    .keyboard__grid{
      background-color: var(--dark__main);
      width: 100%;
      padding: 5px;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }


}
