@import url("Theme.css");

.dropdown__button {
  position: inline-block;
  width: 120px;
  height: 36px;
  border-radius: 10px;
  transition: 0.2s;
  
  background: var(--main);
}

.dropdown__menu__container {
  display: inline-block;
  align-items: center;
  justify-content: space-between;
  background-color: #dcdcdc;
  border-radius: 10px;
  position: relative;
    
  transition: background-color .2s;
}

.dropdown__menu__container__hidden {
  display: none;

}

