@import url("Theme.css");

.scroll-box {
    position: relative;
    width: 100%;
    max-height: 200px;
    overflow: hidden;
    margin-top: 16px;
    z-index: 13;
    
}

.scroll-box__wrapper {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    z-index: 13;
    

}

.scroll-box__wrapper::-webkit-scrollbar {
  height: 0px;
    z-index: 13;
    
}

.scroll-box__container {
    height: 100%;
    display: flex;
    z-index: 13;
    flex-direction: row;
    background-color: var(--main);
}