@import url("Theme.css");


.center__bet__amount__popup__content{
    display:flex;
    flex-direction: column;
    text-align: center;
    width:100%;
    
    align-content: center;
    background-color: var(--main);
    align-items: center;
    
}

.switch__button__container__bet{
    width: 90%;
    height: 40px;
    display: flex;
    margin-top:20px;
    justify-content: space-evenly;
    border-style: solid;
    border-radius: 5px;
    border-color: white;
    border-width: 1px;

}

.button__not__selected__bet {
    color: white;
    background-color: var(--main);
    width: 20%;
    height: 40px;
    cursor:  pointer;
    border-width: 0px;
    border-radius: 4px;
}

.button__selected__bet {
    width: 20%;
    height: 40px;
    color: var(--main);
    cursor:  pointer;
    background-color: white;
    border-radius: 4px;
    border-width: 0px;
}


@media only screen and (max-width: 768px) {
    
    
    .center__bet__amount__popup__content{
        display:flex;
        width:100%;
        background-color: var(--main);
        flex-direction: column;
        align-content: center;
        align-items: center;
        text-align: center;
    }

    
    .switch__button__container__bet{
        width: 90%;
        height: 40px;
        display: flex;
        margin-top:20px;
        justify-content: space-evenly;
        border-style: solid;
        border-radius: 5px;
        border-color: white;
        border-width: 1px;

    }

    .button__not__selected__bet {
        color: white;
        background-color: var(--main);
        width: 20%;
        height: 40px;
        cursor:  pointer;
        border-width: 0px;
        border-radius: 4px;
    }

    .button__selected__bet {
        width: 20%;
        height: 40px;
        color: var(--main);
        cursor:  pointer;
        background-color: white;
        border-radius: 4px;
        border-width: 0px;
    }



}
