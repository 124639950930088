
@import url("../../CSS/Theme.css");


.trivia__results__container{
    width:100%;
    min-width: 350px;
    max-width: 800px;
    display: flex;
    margin-top: 30px;
    align-content: center;
    align-items: center;
    flex-direction: column;

}

.result__image{
    width: 7%;
    height:auto;
    aspect-ratio:1/1;
}

.result__row{
    width: 92%;
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    background-color: var(--dark__main);
    margin: 1px;
    padding-left: 4%;
    padding-right: 4%;
    align-content: center;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
}

@media only screen and (max-width: 768px) {
    

    .trivia__results__container{
        
        width:92%;
        min-width: 100px;
        margin-left:4%;
        display: flex;
        margin-top: 5px;
        align-content: center;
        align-items: center;
        flex-direction: column;

    }
    
    .result__image{
        width: 7%;
        height:auto;
        aspect-ratio:1/1;
    }

    .result__row{
        width: 92%;
        display: flex;
        flex-direction: row;
        border-radius: 20px;
        background-color: var(--dark__main);
        padding-left: 4%;
        padding-right: 4%;
        align-content: center;
        align-items: center;
        vertical-align: middle;
        justify-content: space-between;
    }
    
}