@import url("Theme.css");

.app{
    min-height: 100vh;
    height: 100%;
    background: url('../Assets/green_bg.png') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}


.app::-webkit-scrollbar{
    width:0.5px;
}