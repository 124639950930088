@import url("Theme.css");

.manage__login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.manage__login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 25px;
  padding: 30px;
}

.manage__login__textBox {
  padding: 10px;
  font-size: 18px;
  border-radius: 5px;
  border-width: 0px;
  margin-bottom: 10px;
}

.manage__login__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--accent_yellow);
}

.login__google {
  background-color: #4285f4;
}

.login div {
  margin-top: 7px;
}
.manage__splash__logo{
    width:30%;
    height:auto;
    aspect-ratio:1/1;
}


@media only screen and (max-width: 768px) {
    
    .manage__login {
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .manage__splash__logo{
        width:50%;
        height:auto;
        aspect-ratio:1/1;

    }
    
}