@import url("Theme.css");

.challenge__table__container{
    display:flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width:100%;
}

@media only screen and (max-width: 768px) {
    
    .challenge__table__container{
        display:flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        width:100%;
    }
    
}