
.checkers{
    display:flex;
    align-content: center;
    align-items: center;
    width:100%;
    flex-direction: column;
}

.container {
  height:540px;
  width:auto;
  margin-top:20px;

}
.row {
  height: 60px;
}
.row:nth-child(odd) .cell:nth-child(odd) {
  background-color: #FFE4C4;
  border-radius: 0px;

}
.row:nth-child(odd) .cell:nth-child(even) {
  background-color: var(--main);
  border-radius: 0px;

}
.row:nth-child(even) .cell:nth-child(even) {
  background-color: #FFE4C4;
  border-radius: 0px;

}
.row:nth-child(even) .cell:nth-child(odd) {
  background-color: var(--main);

  border-radius: 0px;
}

.cell {
  height: 60px;
  width: 60px;
  display: inline-block;
}

.cell .gamePiece {
  width: 50px;
  height: 50px;
  margin: 5px 0 0 5px;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
}
.cell .gamePiece:after {
  content: ' ';
  position: absolute;
  right: 0;
  top: -5px;
  height: 48px;
  width: 48px;
  border-radius: 50px;
  pointer-events: none;
}
.cell.cell-b .gamePiece {
  background-color: #444;
}
.cell.cell-b.k .gamePiece:after {
  background-color: #444;

  border: 2px solid purple;
}
.cell.cell-r .gamePiece {
  background-color: #e26b6b;
}
.cell.cell-r.k .gamePiece:after {
  background-color: #e26b6b;
  border: 2px solid purple;
}
.cell.cell-ab .gamePiece,
.cell.cell-ar .gamePiece {
  background-color: #6a6aff;
  box-shadow: 0px 0px 24px #2983f3;
}
.cell.cell-ab.k .gamePiece:after,
.cell.cell-ar.k .gamePiece:after {
  background-color: #6a6aff;
  border: 1px solid white;
}
.cell.cell-h {
  background-color: #6a6aff !important;
}
.cell.m .gamePiece {
  background-color: green;
}
.board.r .cell-b .gamePiece {
  opacity: 0.7;
}
.board.b .cell-r .gamePiece {
  opacity: 0.7;
}
.board {
  transition-duration: 0.8s;
  transition-property: transform;
}
.board.b {
   transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
}


.clear {
  clear: both;
  float: none;
}




@media only screen and (max-width: 768px) {
    .container {
        width: 320px;
        height:320px;
        margin-top:20px;
    }
    .row {
      height: 40px;
    }
    .row:nth-child(odd) .cell:nth-child(odd) {
      background-color: #FFE4C4;
      border-radius: 0px;

    }
    .row:nth-child(odd) .cell:nth-child(even) {
      background-color: var(--main);
      border-radius: 0px;

    }
    .row:nth-child(even) .cell:nth-child(even) {
      background-color: #FFE4C4;
      border-radius: 0px;

    }
    .row:nth-child(even) .cell:nth-child(odd) {
      background-color: var(--main);

      border-radius: 0px;
    }
    .cell {
      height: 40px;
      width: 40px;
      display: inline-block;
    }

    .cell .gamePiece {
      width: 30px;
      height: 30px;
      margin: 5px 0 0 5px;
      border-radius: 40px;
      cursor: pointer;
      position: relative;
    }
    .cell .gamePiece:after {
      content: ' ';
      position: absolute;
      right: 0;
      top: -5px;
      height: 28px;
      width: 28px;
      border-radius: 30px;
      pointer-events: none;
    }
    .cell.cell-b .gamePiece {
      background-color: #444;
    }
    .cell.cell-b.k .gamePiece:after {
      background-color: #444;
    }
    .cell.cell-r .gamePiece {
      background-color: #e26b6b;
    }
    .cell.cell-r.k .gamePiece:after {
      background-color: #e26b6b;

    }
    .cell.cell-ab .gamePiece,
    .cell.cell-ar .gamePiece {
      background-color: #6a6aff;
      box-shadow: 0px 0px 12px #2983f3;
    }
    .cell.cell-ab.k .gamePiece:after,
    .cell.cell-ar.k .gamePiece:after {
      background-color: #6a6aff;
      border: 1px solid white;
    }
    .cell.cell-h {
      background-color: #6a6aff !important;
    }
    .cell.m .gamePiece {
      background-color: green;
    }
    .board.r .cell-b .gamePiece {
      opacity: 0.7;
    }
    .board.b .cell-r .gamePiece {
      opacity: 0.7;
    }
    .board {
      transition-duration: 0.8s;
      transition-property: transform;

    }
    .board.b {
       transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }

    .black__board {
       transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
    }
    .red__board {

    }

    .clear {
      clear: both;
      float: none;
    }

}
