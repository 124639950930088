@import url("Theme.css");

.play {

    flex-direction: column;
    display:flex;
    align-items: center;
    align-content: center;
}


.play__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    align-content: center;
    margin-top: 100px;
    border-radius: 20px;
}


.play__switch__button__container{
    width: 400px;
    height: 40px;
    display: block;
    justify-content: space-evenly;
    border-radius: 5px;
    border-color: white;
    border-width: 1px;
    border-style: solid;
    flex-direction: column;
}

.play__button__not__selected {
    color: white;
    background: var(--main);
    width: 200px;
    height: 40px;
    cursor:  pointer;
    border-width: 0px;
    border-radius: 4px;
}


.play__button__selected {
    width: 200px;
    height: 40px;
    color: var(--main);
    cursor:  pointer;
    background: white;
    border-radius: 4px;
    border-width: 0px;
}



.play__games__container{
    margin-top: 50px;
    display:flex;
    flex-direction: column;
    align-items:center;
    align-content: center;
    text-align: center;
    width:60%;
    
}

.empty__list__text{
    text-align: center;
    align-content: center;
    align-items: center;
    color: white;
    margin-top: 20px;
}

@media only screen and (max-width: 768px) {

    .play {
        height: 100vh;
        flex-direction: column;
        display:flex;
        align-items: center;
        align-content: center;
    }


    .play__container {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        align-content: center;
        margin-top: 100px;
        border-radius: 20px;
    }
    
    .play__switch__button__container{
        width: 300px;
        height: 40px;
        display: block;
        justify-content: space-evenly;
        border-radius: 5px;
        border-width: 1px;
        border-style: solid;
        border-color: white;
    }
    
    .play__games__container{
        margin-top: 20px;
        display:flex;
        flex-direction: column;

        width:90%;
    }

    .play__button__not__selected {
        color: white;
        background: var(--main);
        border-radius: 4px;
        width: 150px;
        height: 40px;
        cursor:  pointer;
        border-width: 0px;
    }

    .play__button__selected {
        width: 150px;
        height: 40px;
        color: var(--main);
        background: white;
        cursor:  pointer;
        border-radius: 4px;
        border-width: 0px;
    }
    
    .empty__list__text{
        text-align: center;
        align-content: center;
        align-items: center;
        color: white;
        margin-top: 20px;
    }
    

}