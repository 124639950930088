@import url("Theme.css");

.ongoing__profile__image{
    cursor: pointer;
    height:auto;
    width:10%;
    border-radius: 100%;
    margin-right: 15px;
    overflow: hidden;
    aspect-ratio:1/1;

}
.ongoing__profile__image__constraint{

    height:auto;
    width:100%;
    aspect-ratio:1/1;

}

.ongoing__game__cell__container{

    display:flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: var(--dark__main);
    width:100%;
    padding:10px;
    margin: 10px;
    align-content: center;
    align-items: center;
    text-align: center;
    border-color: white;
    border-width: 3px;
    border-radius: 10px;
}


.ongoing__game__handle{
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    height:70%;
}

.top__ongoing__info__view{
    display:flex;
    flex-direction: row;

}


.bottom__ongoing__info__view{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    width:100%;

    margin-top:10px;


}

.ongoing__detail__info {
    display:flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    text-align: center;
    vertical-align: bottom;
}


.ongoing__details__image__container{
    width: auto;
    height:20px;
    aspect-ratio:1/1;
    overflow: hidden;

}

.ongoing__details__image{
    width: 100%;
    height:auto;
    overflow: hidden;
    margin-right:2px;
}


@media only screen and (max-width: 768px) {

    .ongoing__profile__image{

        height:auto;
        width:15%;
        border-radius: 100%;
        margin-right: 15px;
        overflow: hidden;
        cursor: pointer;
        aspect-ratio:1/1;



    }
    .ongoing__profile__image__constraint{

        height:auto;
        width:100%;
        aspect-ratio:1/1;

    }

    .ongoing__game__cell__container{

        display:flex;
        flex-direction: column;
        width:100%;
        padding:10px;
        background-color: var(--dark__main);
        align-content: center;
        border-color: white;
        border-width: 1px;
        border-radius: 15px;


    }

    .top__ongoing__info__view{
        display:flex;
        flex-direction: row;


    }


    .ongoing__game__handle{
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        height:40%;
    }


    .ongoing__detail__info {
        display:flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        text-align: center;
        vertical-align: bottom;
    }



    .bottom__ongoing__info__view{
        display:flex;
        margin-top:5px;
        justify-content: space-between;
        flex-direction: row;
        width:100%;

    }

    .ongoing__details__image__container{
        height: 30px;
        width:auto;
        aspect-ratio:1/1;
        overflow: hidden;

    }

    .ongoing__details__image{
        width: 100%;
        height:auto;
        overflow: hidden;
        margin-right:2px;
    }

}
