@import url("Theme.css");



.challenge__cell__container{

    display:flex;
    flex-direction: column;
    width:90%;
    margin-top:10px;
    align-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    border-color: white;
    background-color:var(--dark__main);
    border-width: 2px;
    border-radius: 35px;

}


.challenge__top__info__view{
    display:flex;
    flex-direction: row;
    width:100%;
    justify-content: space-between;

}



.challenge__bottom__info__container{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    height: 40px;
    margin-left: 10px;
    margin-right: 20px;
    width: 100%;
    vertical-align: bottom;
}


.challenge__detail__info {
    display:flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    text-align: center;
    vertical-align: bottom;
}


.challenge__details__image__container{
    height: 70%;
    width:auto;
    margin-right:3px;
    aspect-ratio:1/1;
}


.challenge__details__image{
    width: 100%;
    height:auto;
    aspect-ratio:1/1;
}



.challenge__cell__profile__image{
    height:auto;
    width:10%;
    aspect-ratio:1/1;
    border-color: white;
    border-width: 1px;
    border-radius: 100%;
    overflow: hidden;
    cursor: pointer;
    margin-right:20px;
}




.left__center__aligned{
    display: flex;
    flex-direction: row;
}



.right__top__aligned{

    display: flex;
    flex-direction: column;
    vertical-align: top;
    align-content: flex-start;
    padding-right: 10px;

}


.challenge__cell__profile__image__constraint{
    height:auto;
    width:100%;
    border-radius: 25%;

}

.challenge__profile__handle__type{
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    height:70%;
}


@media only screen and (max-width: 768px) {


    .challenge__cell__container{
        display:flex;
        flex-direction: column;
        width:90%;
        padding: 10px;
        margin-top:10px;

        align-content: center;
        border-color: white;
        border-width: 1px;
        background-color:var(--dark__main);
        border-radius: 20px;
    }


    .challenge__top__info__view{

        display:flex;
        flex-direction: row;
        width:100%;
    }


    .challenge__bottom__info__container{
        display:flex;
        justify-content: space-between;
        flex-direction: row;
        align-content: center;
        align-items: center;
        text-align: center;
        vertical-align: bottom;
        width:100%;
        height: 40px;
    }


    .challenge__detail__info {
        display:flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        text-align: center;
        vertical-align: bottom;
    }


    .challenge__profile__handle__type{
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
        height:70%;
    }



    .challenge__details__image__container{
        height: 25px;;
        width:auto;

        aspect-ratio:1/1;
        overflow: hidden;

    }

    .challenge__details__image{
        width: 100%;
        height:auto;
        overflow: hidden;
        margin-right:2px;
    }



    .challenge__cell__profile__image{
        height:auto;
        width:25%;
        aspect-ratio:1/1;
        border-color: white;
        border-width: 1px;
        border-radius: 50%;
        margin-right:20px;
        cursor: pointer;
    }


    .challenge__cell__profile__image__constraint{
        height:auto;
        width:100%;
        aspect-ratio:1/1;

    }



    .left__center__aligned{

        display: flex;
        flex-direction: row;


    }



    .right__top__aligned{
        padding-right:5px;
        display: flex;
        flex-direction: column;
        vertical-align: top;
        height: 100%;
        justify-content: space-between;


    }



}
