@import url("Theme.css");

.profile__recent__table__container{
    display:flex;
    flex-direction: column;
    align-content: center;
    align-items: center;


}



@media only screen and (max-width: 768px) {
    
    .profile__recent__table__container{
        display:flex;
        align-content: center;
        flex-direction: column;
        align-items: center;

    }

    
}