@import url("Theme.css");

.friends__image__profile{
    height:auto;
    width:5vw;
    aspect-ratio:1/1;
    cursor: pointer;
    margin-right: 8px;
    
    
}
.friends__image__profile__constraint{
    height:auto;
    width:100%;
    aspect-ratio:1/1;
    cursor: pointer;
    border-radius: 50%;
    overflow: hidden;
}

.friends__cell__container{
    margin-top:10px;
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    width:100%;
    align-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid #aaa;


}

.left__info{

    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

@media only screen and (max-width: 768px) {
    
    .friends__image__profile{
        height:auto;
        width:15vw;
        aspect-ratio:1/1;
        cursor: pointer;
        border-radius: 50%;
        margin-right: 8px;
        overflow: hidden;
    }
    
    .friends__image__profile__constraint{
        height:auto;
        width:100%;
        aspect-ratio:1/1;
        cursor: pointer;
    }
    
    .friends__cell__container{
        
        display:flex;
        justify-content: space-between;
        flex-direction: row;
        width:100%;
        align-content: center;
        cursor: pointer;
        border-bottom: 1px solid #fff;
    }
    
    
    .left__info{
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        font-size: 14px;
    }

    
}