.chess{
    display:flex;
    align-content: center;
    align-items: center;
    width:100%;
    flex-direction: column;
}



.board__frame{
  cursor: pointer;

    margin-top:5px;
    align-content: center;
    align-items: center;
}

.vertical__auto{
    display: flex;
    background-color: var(--dark__main);
    border-radius: 10px;
    flex-direction: column;
    height: auto;
    width: 50%;
    margin:2px;
    padding:10px;
}

.top__info__view
{
    width: 30%;
    display: flex;
    min-width: 600px;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-top: 60px;
    justify-content: space-between;

}

@media only screen and (max-width: 768px) {


    .chess{
        display:flex;
        align-content: center;
        align-items: center;
        width:100%;
        flex-direction: column;

    }

    .board__frame{

        margin-top:20px;
        align-content: center;
        align-items: center;
        cursor: pointer;
    }


    .top__info__view{
        width: 94%;
        display: flex;
        min-width: 94%;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: space-evenly;
    }


    .vertical__auto{
        background-color: var(--dark__main);
        border-radius: 10px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        padding:5px;
        height: auto;
        width: 50%;
    }
}
