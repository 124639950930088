@import url("Theme.css");

.request__profile__image{
    height:auto;
    width:5vw;
    border-color: white;
    border-width: 1px;
    border-radius: 50%;
    margin-right:10px;
    aspect-ratio:1/1;
    overflow: hidden;
    
}
.request__profile__image__constraint{
    height:auto;
    width:100%;
    aspect-ratio:1/1;
    cursor: pointer;
    overflow: hidden;
}

.request__cell__container{
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    width:100%;
    margin-top:10px;
    border-radius: 10px;
    background-color: var(--dark__main);
    padding:10px;
    align-content: center;
}

.name__handle__container{
    height:50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.left__info{

    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}


@media only screen and (max-width: 768px) {
    
    .request__profile__image{
        height:auto;
        width:15vw;
        border-radius: 50%;
        aspect-ratio:1/1;
        cursor: pointer;
        margin-right:10px;
        overflow: hidden;        
    }
    
    .request__profile__image__constraint{
        height:auto;
        width:100%;
        aspect-ratio:1/1;  
        overflow: hidden;
    }
    
    .name__handle__container{
        height:50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
    
    .request__cell__container{
        display:flex;
        justify-content: space-between;
        flex-direction: row;
        width:100%;
        margin-top:10px;
        padding:10px;
        border-radius: 10px;
        background-color: var(--dark__main);
        align-content: center;
    }
    
    .left__info{

        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
    }
    
}