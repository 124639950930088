@import url("Theme.css");

.friends__table__container{
    display:flex;
    flex-direction: column;
    align-content: center;
    margin-top:30px;
    align-items: center;
    width:100%;
}

.friends__cell__underline{
    border-bottom: 1px solid white;
    margin-left: 40px;

}

@media only screen and (max-width: 768px) {
    
    .friends__table__container{
        display:flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        margin-top:30px;
        width:100%;
    }
    .friends__cell__underline{
        margin-left: 30px;
        border-bottom: 1px solid white;
    }
}