
@import url("Theme.css");


.manage__balance{
    flex-direction: column;
    display:flex;
    align-items: center;
    align-content: center;
}

.manage__balance__container{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    align-items: center;
    margin-top: 100px;
    width:70%;
    justify-content: space-between;
}

.balance__actions__row{
    flex-direction: row;
    display:flex;
    align-items: center;
    align-content: center;
    
    justify-content: space-around;
    
}


.address__info__container{
    width: 40%;
    max-width: 400px;
    flex-direction: column;
    display:flex;
    align-items: center;
    align-content: center;
    justify-content: space-around;
}




@media only screen and (max-width: 768px) {
    
    .manage__balance{
        flex-direction: column;
        display:flex;
        align-items: center;
        align-content: center;
    }

    .manage__balance__container{
        display: flex;
        flex-direction: column;
        text-align: start;
        align-content: center;
        margin-top: 50px;
        padding-left:2vw;
        padding-right:2vw;
        justify-content: space-between;
        width:96vw;
    }
    
    .address__info__container{
        width: 90%;
        flex-direction: column;
        display:flex;
        align-items: center;
        align-content: center;
        justify-content: space-around;
        padding-bottom: 100px;
    }
}