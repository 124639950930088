@import url("../../CSS/Theme.css");

.wordup{
    display:flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding-top:85px;
}

.wordup__top__info__view{
    width: 30%;
    display: flex;
    min-width: 600px;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}


.wordup__board {
  height: 360px;
  width: 360px;
  margin: 0 auto;
  margin-top: 15px;
}

.innerBoard {
  background-color: var(--dark__main);
  border-radius: 10px;
  height: 340px;
  width: 340px;
  padding: 5px;
  cursor: pointer;
}

.letters {
  margin: 5px;
  width: 74px;
  height: 74px;
  display: inline-block;
  background-color: white;
  border-radius: 4px;
  box-shadow: -2px 2px 2px #343738;
}
.text {
  margin: 26px;
  text-align: center;
  font-family: arial;
  font-weight: bold;
}

.activeLetter {
  background-color: #ACCEEC;
  margin: 5px;
  width: 74px;
  height: 74px;
  display: inline-block;
  border-radius: 4px;
  box-shadow: -2px 2px 2px #343738;
}

.words__list{

  display:flex;
  align-content: center;
  align-items: center;
  width: 430px;

}

.word__list__text{
  color: var(--gray);
  font-size: 22px;
  text-align: start;

}


.game__info__container{
    width: 30%;
    min-width:600px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    border-radius:10px;
}

.opponent__info__container{
    padding:5px;
    display: flex;
    align-content: center;
    align-items: center;
    vertical-align: middle;
    width: auto;
    flex-direction: row;
}


.profile__image {
    width:20%;
    height:auto;
    aspect-ratio:1/1;
    border-radius: 50%;
    overflow: hidden;
}

.handle__text{
    margin-left: 16px;
    color: gray;
}


.vertical__auto{
    display: flex;
    background-color: var(--dark__main);
    border-radius: 10px;
    flex-direction: column;
    height: auto;
    width: 50%;
    margin:2px;
    padding:10px;
}








@media only screen and (max-width: 768px) {

    .wordup{
        padding-top:55px;
        display:flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }

    .wordup__top__info__view{
        width: 94%;
        display: flex;
        min-width: 94%;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: space-evenly;
    }


    .opponent__info__container{
        padding:5px;
        display: flex;
        align-content: center;
        align-items: center;
        vertical-align: middle;
        width: auto;
        flex-direction: row;
    }


    .profile__image{
        width:10%;
        height:auto;
        aspect-ratio:1/1;
        border-radius: 50%;
        overflow: hidden;
    }


    .handle__text{
        margin-left: 10px;
        color: gray;
    }


    .game__info__container{

        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        border-radius:10px;

    }

    .vertical__auto{
        background-color: var(--dark__main);
        border-radius: 10px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        padding:5px;
        height: auto;
        width: 50%;
        align-items: center;
    }



    .gameRoot {
      width: 300px;
      margin: 0 auto;
      padding: 20px 0 20px 0;
    }


    .wordup__board {
      height: 250px;
      width: 250px;
      margin: 0 auto;
      margin-top: 15px;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    .words__list{
      display:flex;
      align-content: center;
      align-items: center;
      width:98%;
      margin-left: 1%;

    }
    .innerBoard {
      background-color: var(--dark__main);
      border-radius: 10px;

      height: 236px;
      width: 236px;
      padding: 5px;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    .letters {
      margin: 5px;
      width: 48px;
      height: 48px;
      display: inline-block;
      background-color: white;
      border-radius: 4px;
      box-shadow: -2px 2px 2px #343738;
    }
    .activeLetter {
      background-color: #ACCEEC;
      margin: 5px;
      width: 48px;
      height: 48px;
      display: inline-block;
      border-radius: 4px;
      box-shadow: -2px 2px 2px #343738;
    }

    .text {
      margin: 16px;
      text-align: center;
      font-family: arial;
      font-weight: bold;
    }
    .word__list__text{
      color: var(--gray);
      font-size: 14px;
      text-align: start;

    }

}
