@import url("../../CSS/Theme.css");


.center__choose__word__popup__content{
    display:flex;
    flex-direction: column;
    text-align: center;
    width:100%;
    align-content: center;
    background-color: var(--main);
    align-items: center;

}



@media only screen and (max-width: 768px) {


    .center__choose__word__popup__content{
        display:flex;
        width:100%;
        background-color: var(--main);
        flex-direction: column;
        align-content: center;
        align-items: center;
        text-align: center;
    }




}
