
@import url("../../CSS/Theme.css");

.trivia{
    display:flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding-top:85px;
    
}


.trivia__game__container{
    width:100%;
    min-width: 350px;
    max-width: 800px;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
}


.trivia__top__info__view{
    width: 30%;
    display: flex;
    min-width: 600px;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;

}

.game__info__container{
    width: 30%;
    min-width:600px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    background-color: var(--dark__main);
    border-radius:10px;
}

.opponent__info__container{
    padding:5px;
    display: flex;
    align-content: center;
    align-items: center;
    vertical-align: middle;
    width: auto;
    flex-direction: row;
}

    
.profile__image {
    width:20%;
    height:auto;
    aspect-ratio:1/1;
    border-radius: 50%;
    overflow: hidden;
}

.handle__text{
    margin-left: 16px;
    color: gray;
}   


.vertical__auto{
    display: flex;
    background-color: var(--dark__main);
    border-radius: 10px;
    flex-direction: column;
    height: auto;
    width: 50%;
    margin:2px;
    padding:10px;
}


.answer__container {
    display:flex;
    flex-direction: row;
    width:100%;
    justify-content: space-between;
    padding:10px;
    margin:10px;
    font-size: 26px;
    border-radius: 20px;
    background-color: var(--dark__main);
    text-align: center;
    vertical-align: middle;
    color: white;
}


.question__container {
    display:flex;
    padding:10px;
    margin-top:40px;
    margin-bottom:10px;
    font-size: 30px;
    color: white;
}





@media only screen and (max-width: 768px) {
    
    .trivia{
        padding-top:55px;
        display:flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
    }
    
    
    .trivia__game__container{
        width:96%;
        display: flex;
        flex-direction: column;
    }
    
    
    .trivia__top__info__view{
        width: 94%;
        display: flex;
        min-width: 94%;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: space-evenly;
    }
    
    
    .opponent__info__container{
        padding:5px;
        display: flex;
        align-content: center;
        align-items: center;
        vertical-align: middle;
        width: auto;
        flex-direction: row;
    }

    
    .profile__image{
        width:10%;
        height:auto;
        aspect-ratio:1/1;
        border-radius: 50%;
        overflow: hidden;
    }
    
    
    .handle__text{
        margin-left: 10px;
        color: gray;
    }
    
        
    .game__info__container{
        width: 94%;
        min-width: 94%;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        background-color: var(--dark__main);
        border-radius:10px;
        
    }
    
    .vertical__auto{
        background-color: var(--dark__main);
        border-radius: 10px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        padding:5px;
        height: auto;
        width: 50%;
    }
    
    
    .answer__container {
        display:flex;
        padding:5px;
        margin:5px;
        width:100%;
        background-color: var(--dark__main);
        text-align: center;
        color: white;
        flex-direction: row;
        justify-content: space-between;
        vertical-align: middle;
        border-radius: 10px;
        overflow: hidden;

    }

    .question__container {
        display:flex;
        margin:5px;
        font-size: 18px;
        color: white;
        max-width: 600px;

    }

    
}