:root{
    --text_main: white;
    --main: #0A572A;
    --dark__main: #06361A;
    --green_confirm: #06D6A0;
    --gray: #B2BABB;
    --accent_yellow: #FFDA3C;
    --purple_primary: #6A33AD;
}



.add_border{
    border-radius: 5px;
    border-width: 1px;
    border-color: var(--main);
    align-items: center;
    align-content: center;
}


.title{
    color: white;
    margin-top:50px;
    padding: 0.2rem calc((100vw - 1000px) / 2);
    font-size: 28px;
}
.cell__text{
  color: white;
  font-size: 22px;
  text-align: start;
}

.title__yellow{
    color: var(--accent_yellow);
    font-size: 28px;
    font-weight: bold;
    text-align: start;
}
.title__gray{
    color: var(--gray);
    font-size: 28px;
    font-weight: bold;
    text-align: start;
}
.no__margins__no__padding{
  padding:0;
  margin:0;
}

.dynamic__loading__container{
    color: white;
    align-items: center;
    align-content: center;
    min-width:100px;
}

.small__loading__container{
    color: white;
    margin:0px;
    padding:0px;
    align-items: center;
    align-content: center;
    width:70px;
}



.pony__logo{
    width:auto;
    height:70px;
    aspect-ratio:1/1;

}
.large__yellow{
    color: var(--accent_yellow);
    font-size: 22px;
    font-weight: bold;
}
.large__white{
    color: white;
    font-size: 22px;
    font-weight: bold;
}
.large__gray{
    color: var(--gray);
    font-size: 22px;
    font-weight: bold;
}

.medium__yellow{
    color: var(--accent_yellow);
    font-size: 16px;
    font-weight: bold;
}

.bold__white__large__text{

    color: white;
    font-size: 24px;
    text-align: start;
    font-weight: bold;

}


.bold__black__text{

  color: black;
  font-size: 24px;
  text-align: start;
  font-weight: bold;
}
.bold__gray__large__text{

    color: lightgray;
    font-size: 24px;
    text-align: start;
    font-weight: bold;

}
.bold__darkgray__large__text{

    color: darkgray;
    font-size: 24px;
    text-align: start;
    font-weight: bold;

}

.small__text{
    color: white;
    font-size: 11px;
    text-align: start;
}

.medium__text{
    color: white;
    font-size: 16px;
    text-align: start;
}

.large__text{
    color: white;
    font-size: 22px;
    text-align: start;
}

.yellow__start__text{
    color: var(--accent_yellow);
    font-size: 22px;
    text-align: start;
    font-weight: bold;
}

.small__text__gray{
    color: var(--gray);
    font-size: 11px;
    text-align: start;
}

.medium__text__gray{
    color: var(--gray);
    font-size: 16px;
    text-align: start;
}
.right__medium__text__gray{
    color: var(--gray);
    font-size: 16px;
    text-align: end;
}


.large__text__gray{
    color: var(--gray);
    font-size: 22px;
    text-align: start;
}
.small__text__gray{
    color: var(--gray);
    font-size: 12px;
    text-align: start;
}
.title__left{
    color: white;
    margin-top:50px;
    font-size: 23px;
    text-align: start;
}

.card__title{
    color: white;
    font-size: 28px;
    margin-bottom: 1rem;
    text-align: start;
}

.title__left__small{
    color: white;
    margin-top:50px;
    font-size: 11px;
    text-align: start;

}

.info{
    padding: 0.2rem calc((100vw - 1000px) / 2);
    margin-top: 30px;
    font-size: 18px;
    background: lightgray;
}


.hidden {
    display:none;
}

.small__spacer__horizontal{
    margin-right:8px;
}

.spacer__horizontal{
    margin-right:16px;
}
.small__spacer__vertical{
    margin-right:8px;
}

.spacer__vertical{
    margin-top:16px;
}
.button__collumn {
    display: flex;
    font-size: 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.body__flex__fill{
    height: 95vh;
    width: 100%;
    padding-top: 85px;
    align-content: center;
    align-items: center;
    text-align: center;
}

.iframe__fill{
    display: block;
    margin:auto;
    width:50%;
    height:94%;
    border-width: 0px;

}

@media only screen and (max-width: 768px) {

    .body__flex__fill{
        height: 90vh;
        width: 100%;
        align-content: center;
        align-items: center;
        text-align: center;

    }

    .iframe__fill{
        display: block;
        margin:auto;
        width:98%;
        height:98%;
        border-width: 0px;

    }

    .pony__logo{

        width:auto;
        height:40px;
        aspect-ratio:1/1;

    }

    .cell__text{
      color: white;
      font-size: 14px;
      text-align: start;
    }
}



.white__background__theme__button {
    font-size: 18px;
    margin: 3px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    color:var(--main);
    vertical-align: middle;
    align-content: center;
    align-items: center;
    cursor: pointer;
    background-color: white;
}


.yellow__background__black__button {
    font-size: 18px;
    margin: 3px;
    padding: 4px;
    border: none;
    border-radius: 5px;
    color:black;
    cursor: pointer;
    background-color: var(--accent_yellow);
}

.white__background__theme__button__no__margin {
    font-size: 18px;
    padding: 4px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color:var(--main);
    background-color: white;
}


.dark__theme__background__white__button{
    font-size: 18px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    color:white;
    cursor: pointer;
    background-color: var(--dark__main);

}


.theme__background__white__button {
    font-size: 18px;
    margin: 30px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color:white;
    cursor: pointer;
    background-color: var(--main);
}
.theme__background__white__button__no__margin{
    font-size: 18px;
    margin: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color:white;
    cursor: pointer;
    background-color: var(--main);

}

.theme__background__gray__button {
    font-size: 16px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    color:lightgray;
    cursor: pointer;
    background-color: var(--main);
}

.dark__theme__background__gray__button {
    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color:lightgray;
    cursor: pointer;
    background-color: var(--dark__main);
}


.theme__background__yellow__button {
    font-size: 16px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color:var(--accent_yellow);
    cursor: pointer;
    background-color: var(--main);
}

.small__theme__background__yellow__button {
    font-size: 12px;
    border-radius: 5px;
    color:var(--accent_yellow);
    cursor: pointer;
    margin-left:10px;
    font-weight: bold;

}




.white__background__gray__button {
    font-size: 16px;
    margin: 30px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color:gray;
    cursor: pointer;
    background-color: white;
}

.white__background__gray__button__small {
    font-size: 13px;
    margin: 10px;
    padding: 4px;
    border: none;
    border-radius: 5px;
    color:gray;
    cursor: pointer;
    background-color: white;
}


.white__background__gray__button__no__margin {
    font-size: 13px;

    padding: 5px;
    border: none;
    border-radius: 5px;
    color:gray;
    cursor: pointer;
    background-color: white;
}
.vertical__flex__center{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items:center;
    vertical-align: middle;
    height:100%;
    justify-content: space-around;
}

.horizontal__flex__center{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items:center;

}
.horizontal__center__space__between{

  display: flex;
  flex-direction: row;
  align-content: center;
  align-items:center;
  justify-content: space-between;

}
.gray__background__white__button {
    font-size: 18px;
    margin: 30px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color:white;
    cursor: pointer;
    background-color: gray;
}


.green__confirm__button {
    font-size: 18px;
    margin: 30px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    color:white;
    cursor: pointer;
    background-color: var(--green_confirm);
}





.input__textbox{
    font-size: 18px;
    border-radius: 5px;
    resize: none;
    border-width: 0px;
    padding:10px;
    margin-top:5px;
    width:90%;
    display: flex;
}
