@import url("Theme.css");


.friends {
    height:100vh;
    flex-direction: column;
    display:flex;
    align-items: center;
    
}


.friends__container {
    display: flex;
    flex-direction: column;
    text-align: start;
    align-content: center;
    align-items: center;
    margin-top: 100px;
    padding-bottom: 80px;
}


.switch__button__container__friends{
    width: 30vw;
    height: 40px;
    display: flex;
    margin-top:20px;
    justify-content: space-evenly;
    border-style: solid;
    border-radius: 5px;
    border-color: white;
    border-width: 1px;

}

.button__not__selected__friends {
    color: white;
    background-color: var(--main);
    width: 15vw;
    height: 40px;
    cursor:  pointer;
    border-width: 0px;
    border-radius: 4px;
}

.button__selected__friends {
    width: 15vw;
    height: 40px;
    color: var(--main);
    cursor:  pointer;
    background-color: white;
    border-radius: 4px;
    border-width: 0px;
}


.top__view{
    justify-content: space-between;
    display: flex;
    width: 40vw;
    align-items: center;
    align-content: center;
}


.empty__list__text{
    text-align: center;
    align-content: center;
    color: white;
    margin-top: 20px;
}

.all__friends__container{
    display: flex;
    flex-direction: column;
    width:40vw;
}

.search__friends__container{
    display: flex;
    flex-direction: column;
    width:40vw;
}

@media only screen and (max-width: 768px) {


    .friends{
        flex-direction: column;
        display:flex;
        align-items: center;
    }
    
    .friends__container {
        display: flex;
        width:96vw;
        flex-direction: column;
        text-align: start;
        align-content: center;
        margin-top: 85px;
        padding-bottom: 80px;


    }
    
    .switch__button__container__friends{
        width: 90vw;
        height: 30px;
        display: block;
        border-style: solid;
        margin-top:20px;
        justify-content: space-evenly;
        border-radius: 5px;
        border-width: 1px;
        border-color: white;
    }

    
    .button__not__selected__friends {
        color: white;
        background-color: var(--main);
        border-radius: 4px;
        width: 45vw;
        height: 30px;
        cursor:  pointer;
        border-width: 0px;
    }

    .button__selected__friends {
        width: 45vw;
        height: 30px;
        color: var(--main);
        background-color: white;
        cursor:  pointer;
        border-radius: 4px;
        border-width: 0px;
    }
    
    .top__view{
        justify-content: space-between;
        width: 90vw;
        display: flex;
        align-items: center;
        align-content: center;
    }
    
    
    .all__friends__container{
        display: flex;
        flex-direction: column;
        width:90vw;



    }
    
    .search__friends__container{
        display: flex;
        flex-direction: column;
        width:98vw;
}
    
    
}