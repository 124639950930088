@import url("Theme.css");



.account {
    padding-top: 90px;
    flex-direction: column;
    display:flex;
    align-items: center;
    padding-bottom: 100px;
}


.account__container {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: 40vw;
    padding: 10px;
}


.balance{
    justify-content: space-around;
    display: block;
    align-items: center;
    align-content: center;
}

.handles{
    justify-content: space-around;
    align-items: center;
    align-content: center;
    display: block;
}

.profile__info{
    display: flex;
    width: 40vw;
    justify-content: space-between;
    margin-bottom: 30px;

}

.line{
    height:2px;
    width: 40vw;
    background-color: white;
    align-content: center;
    align-items: center;
}

.record{
    display: flex;
    width: auto;
    margin-bottom: 10px;
    align-content: center;
    align-items: center;
    text-align: center;
}

.user__image{
    border-radius: 50%;
    border-color: white;
    border-style: solid;
    height: auto;
    aspect-ratio: 1/1;
    width: 20%;
    max-width: 400px;
    
}


.ongoing__profile__games__container{
    margin-top: 50px;
    display:flex;
    width: 40vw;
    align-items:center;
    align-content: center;
    text-align: center;
    justify-content:space-around; /* horizontally center */
    
}


.edit__button{
    height: 20px;
    width: 20px;
    margin-left: 10px;
    cursor:pointer;
}


@media only screen and (max-width: 768px) {

    .account{
        padding-top: 55px;
        
        flex-direction: column;
        display:flex;
        align-items: center;
        align-content: center;
    }

    .account__container {
        display: flex;
        font-size: 18px;
        flex-direction: column;
        align-content: center;
        align-items: center;
        width: 80vw;

    }
    .balance{
        justify-content: space-around;
        display: block;
        align-items: center;
        align-content: center;
    }

    .handles{
        justify-content: space-around;

        align-items: center;
        align-content: center;
        display: block;
    }
    
    .profile__info{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-content: center;
        align-items: center;
        width:90vw;

    }
    
    .record{
        margin-bottom: 20px;
        align-content: center;
        align-items: center;
}
    
    
    .line{
        height:1px;
        width: 80vw;
        background-color: white;
        align-content: center;
        align-items: center;
    }

    .user__image{
        border-radius: 50%;
        height: auto;
        aspect-ratio: 1/1;
        border-color: white;
        border-style: solid;
        width: 45%;
    }
    
    
    .edit__button{
        height: 16px;
        width: 16px;
        margin-left: 10px;
        cursor:pointer;
    }

        
    .ongoing__profile__games__container{
        margin-top: 20px;
        display:flex;
        width: 90vw;
        justify-content:space-around; /* horizontally center */
        align-items:center;
        align-content: center;
        text-align: center;
    }
    
}