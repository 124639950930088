.xsos{
    display:flex;
    align-content: center;
    align-items: center;
    width:100%;
    flex-direction: column;
}


.board-row:after {
    clear: both;
    content: "";
    display: table;
}
.board__frame{
    align-content: center;
    align-items: center;
}

.square {
    color: lightgray;
    background: var(--main);
    border: 4px solid var(--dark__main);
    float: left;
    font-size: 124px;
    font-weight: bold;
    line-height: 130px;
    height: 158px;
    cursor: pointer;
    padding: 0;
    text-align: center;
    width: 158px;
}

.square:focus {
    outline: none;
}

.kbd-navigation .square:focus {
    background: #ddd;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.game {
    display: flex;
    flex-direction: column;
}


.vertical__auto{
    display: flex;
    background-color: var(--dark__main);
    border-radius: 10px;
    flex-direction: column;
    height: auto;
    width: 50%;
    margin:2px;
    padding:10px;
}

.xsos__top__info__view
{
    width: 30%;
    display: flex;
    min-width: 600px;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-top: 60px;
    justify-content: space-between;

}

@media only screen and (max-width: 768px) {


    .xsos{
        display:flex;
        align-content: center;
        align-items: center;
        width:100%;
        flex-direction: column;
    }

    .square {
        color: lightgray;
        background: var(--main);
        border: 4px solid var(--dark__main);
        float: left;
        font-size: 56px;
        font-weight: bold;
        line-height: 64px;
        height: 64px;
        padding: 0;
        text-align: center;
        width: 80px;
    }


    .board__frame{
        align-content: center;
        align-items: center;
        margin-top: 0px;
    }


    .xsos__top__info__view{
        width: 94%;
        display: flex;
        min-width: 94%;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: space-evenly;
    }


    .vertical__auto{
        background-color: var(--dark__main);
        border-radius: 10px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        padding:5px;
        height: auto;
        width: 50%;
    }


    .square {
        color: lightgray;
        background: var(--main);
        border: 4px solid var(--dark__main);
        float: left;
        font-size: 76px;
        font-weight: bold;
        line-height: 76px;
        height: 92px;
        cursor: pointer;
        margin-right: -1px;
        margin-top: -1px;
        padding: 0;
        text-align: center;
        width: 92px;
    }

    .square:focus {
        outline: none;
    }

    .kbd-navigation .square:focus {
        background: #ddd;
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .game {
        display: flex;
        flex-direction: column;
    }




}
