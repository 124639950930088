.profile__popup__image__container {
    vertical-align: middle;
    align-content: center;
    align-items: center;
    display: flex;
    background: var(--main);
    height: auto;
    aspect-ratio: 1/1;
    width: 20vw;
    overflow-x: hidden;
    overflow-y: hidden;   
}

.profile__popup__user__image{
    border-radius: 20vw;
    height: auto;
    align-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    width: 20vw;
}

.center__profile__content{
    display:flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    align-items: center;
    
    
}


.profile__recent__games__container{
    display:flex;
    align-content: center;
    align-items: center;
    width: 40vw;
    text-align: center;
    
}

.horizontal__name__handle{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    align-content: center;
    align-items: center;
    vertical-align: middle;
}

.user__handle__text{
    margin-left:10px;
    color:white;
    font-size: 16px;
}


.top__close__view{
    display: flex;
    flex-direction: row;
    width: 40vw;
    justify-content: space-between;
}


.name__text__profile{
    font-size: 18px;
    font-weight: bold;
    align-content: flex-start;
    align-items: flex-start;
    text-align: start;
    width: 40vw;
    color:var(--accent_yellow);
    margin-top:10px;
}

.horizontal__fill__buttons{
    display:flex;
    margin-top:10px;
    width: 40vw;
    justify-content: space-between;
}

@media only screen and (max-width: 768px) {
    
    .horizontal__name__handle{
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: center;
        align-content: center;
        align-items: center;
        vertical-align: middle;

    }
    
    .center__profile__content{
        display:flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        text-align: center;
    }
    
    .profile__popup__image__container {
        vertical-align: middle;
        align-content: center;
        display: flex;
        background: var(--main);
        height: auto;
        aspect-ratio: 1/1;
        width: 40vw;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .profile__popup__user__image{
        border-radius: 20vw;
        align-content: center;
        height: auto;
        aspect-ratio: 1/1;
        width: 40vw;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    
    .profile__recent__games__container{
        display:flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        text-align: center;
        width:90vw;
    }
    
    .user__handle__text{
        margin-left:10px;
        color:white;
        font-size: 14px;

    }
    
    .top__close__view{
        display: flex;
        flex-direction: row;
        width: 90vw;
        justify-content: space-between;
    }
    
    .name__text__profile{
        font-size: 16px;
        font-weight: bold;
        color:var(--accent_yellow);
        margin-top:10px;
        align-content: flex-start;
        align-items: flex-start;
        text-align: start;
        width: 90vw;
    
    }
    
    .horizontal__fill__buttons{
        display:flex;
        margin-top:10px;
        width: 90vw;
        justify-content: space-between;
    }



}