@import url("Theme.css");

.recent__profile__image{
    height:70px;
    width:70px;
    margin-top: 5px;
    cursor: pointer;
    
    
}
.recent__profile__image__constraint{
    height:70px;
    width:70px;
    border:solid;
    border-color: white;
    border-width: 2px;
    border-radius: 35px;
    overflow-x: hidden;
    overflow-y: hidden;
    cursor: pointer;
}

.recent__cell__container{
    padding: 5px;
    display:flex;
    flex-direction: column;
    width:60vw;
    align-content: center;
    border-color: white;
    border-width: 3px;
    border-radius: 35px;
}

.user__info__container{
        
    display: flex;
    flex-direction: row;
}
    
.background__button{

    position: fixed;
    top:0;
    left:25%;
    height:100%;
    width: 50%;
}

.vs__text{
    color:gray;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    
}
    
.trash__talk{
    color:white;
    font-size: 16px;
}
    
.recap__text{
    color:var(--accent_yellow);
    font-size: 18px;
}
.opponent__name{
    color:white;
    margin-right: 10px;
    margin-top: 10px;
    text-align: end;
    font-size: 22px;
}
.challenger__name{
    color:white;
    margin-left: 10px;
    
    text-align: start;
    font-size: 22px;
    margin-top: 10px;
    
}

.vs__container{
        
    align-content: center;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;  
}

@media only screen and (max-width: 768px) {
    
    .recent__profile__image{
        height:40px;
        width:40px;
        cursor: pointer;
        border-color: white;
        border-width: 1px;
        border-radius: 25px;
    }
    .recent__profile__image__constraint{
        height:40px;
        width:40px;
        cursor: pointer;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    
    .recent__cell__container{
        padding: 5px;
        display:flex;
        width: 96vw;
        flex-direction: column;
        align-content: center;
        border-color: white;
        border-width: 1px;
        border-radius: 35px;
    }
    
    
    
    .user__info__container{
        display: flex;
        flex-direction: row;
    }
    
    .vs__text{
        color:gray;
        margin-bottom: 5px;
        font-size: 16px;
        text-align: center;
        justify-content: space-evenly
    }
    
    .trash__talk{
        color:lightgray;
        font-size: 12px;
    }
    
    .recap__text{
        color:var(--accent_yellow);
        font-size: 14px;
    }
    
    .opponent__name{
        color:white;
        text-align: end;
        font-size: 14px;
    }
    .challenger__name{
        color:white;
        text-align: start;
        font-size: 14px;
    }
    .vs__container{
        
        align-content: center;
        align-items: center;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        
    }
    
}