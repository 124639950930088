@import url("Theme.css");


.register {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: row;  
  justify-content: center;
}

.register__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 30px;
}

.register__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.register__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--accent_yellow);
}

.register__google {
  background-color: #4285f4;
}

.register div {
  margin-top: 7px;
}


.splash__logo{
    width:30%;
    height:auto;
    aspect-ratio:1/1;

}


@media only screen and (max-width: 768px) {
    
    .register {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    
    .splash__logo{
        width:50%;
        height:auto;
        aspect-ratio:1/1;

    }
    
}