
.center__gameover__content{
    display:flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    align-items: center; 
}



.top__close__gameover__view{
    display: flex;
    flex-direction: row;
    width: 90%;
    align-content: center;
    align-items: center;
    margin-top:20px;
    margin-bottom:10px;
    justify-content: space-between;
}

.message__box{
    display: flex;
    flex-direction: column;
    width:90%;
    margin-bottom:20px;
    background-color: var(--dark__main);
    padding:10px;
    border-radius: 10px;
    border-width: 0px;
    overflow: hidden;
}


.opponent__container{
    display: flex;
    width:90%;
    margin-top:20px;
    margin-bottom:20px;
    flex-direction: column;
    align-items: center;
    padding-bottom:5px;
    padding-right:5px;
    padding-left:5px;
    border-radius: 10px;
    background-color: var(--dark__main);
    align-content: center;
}



@media only screen and (max-width: 768px) {
    

    .opponent__container{
        border-radius: 10px;
        background-color: var(--dark__main);
        display: flex;
        width:90%;
        padding-bottom:5px;
        padding-right:5px;
        padding-left:5px;
        margin-top:20px;
        margin-bottom:20px;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    
    
    .vertical__gameover__name__handle{
        display: flex;
        margin-left: 10px;
        flex-direction: column;
        justify-content: space-around;

    }
    
    .game__info__view{
        display:flex;
        flex-direction:row;
        width:90%;
        padding:5px;
        border-radius: 10px;
        background-color: var(--dark__main);
        align-content: center;
        align-items: center;
        margin-bottom: 16px;
    }
    
    
    .top__close__gameover__view{
        display: flex;
        flex-direction: row;
        width: 90%;
        align-content: center;
        margin: 0px;
        align-items: center;
        justify-content: space-between;
    }
    
    .center__gameover__content{
        display:flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        text-align: center;
    }
    
   
    
    
    
    
    
    .message__box{
        display: flex;
        flex-direction: column;
        width:90%;
        margin-bottom:20px;
        background-color: var(--dark__main);
        padding:10px;
        border-radius: 10px;
        border-width: 0px;
        overflow: hidden;
    }



}