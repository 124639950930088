
.card__container{
    position: relative;
    display: flex;
    width:19vw;
    aspect-ratio: 3/2;
    height: auto;
    cursor: pointer;
    background-color: var(--main)
}

.image {
    height: auto;
    aspect-ratio: 3/2;
    width: 19vw;
    color:var(--text_main);
    position: relative;
    overflow: hidden;
    align-content: center;
    align-items: center;
    z-index: 900;
    position: absolute;
}

.image__constraint{
    width:19vw;
    aspect-ratio: 3/2;
    height: auto;
    
}
.overlay{
    background-color: black;
    opacity: 60%;
    z-index: 1000;
    width:19vw;
    aspect-ratio: 3/2;
    height: auto;
    position: absolute;
}
    
    
.overlay__text{
    color:white;
    font-weight: bold;
    position: absolute;
    text-align: end;
    margin-left: 10px;
    margin-top:10px;
    
    z-index: 1001;
    font-size: 20px;
    vertical-align: bottom;
        
}



@media only screen and (max-width: 768px) {

    .card__container{
        position: relative;
        display: flex;
        aspect-ratio: 3/2;
        height: auto;
        width: 30vw;
        cursor: pointer;
        background-color: var(--main)
    }
    
    
    .overlay{
        background-color: black;
        opacity: 60%;
        z-index: 1000;
        width:30vw;
        aspect-ratio: 3/2;
        height: auto;
        z-index: 1000;
        position: absolute;
    }
    
    
    .overlay__text{
        color:white;
        margin-left: 5px;
        margin-top:5px;
        font-weight: bold;
        position: absolute;
        text-align: end;
        z-index: 1001;
        font-size: 14px;
        vertical-align: bottom;
    }
    
    .image {
        aspect-ratio: 3/2;
        height: auto;
        width: 30vw;
        display: block;
        position: absolute;
        z-index: 900;
        color:var(--text_main);

    }
    
    .image__constraint{
        width:30vw;
        aspect-ratio: 3/2;
        height: auto;
    }


}