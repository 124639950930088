@import url("Theme.css");

.recent__table__container{
    display:flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width:100vw;
}

.recent__cell__underline{
    border-bottom: 1px solid white;
}

@media only screen and (max-width: 768px) {
    
    .recent__table__container{
        display:flex;
        align-content: center;
        flex-direction: column;
        align-items: center;
        width:100vw;
    }
    
    .recent__cell__underline{
        border-bottom: 1px solid white;
    }
    
}