@import url("Theme.css");

.center__support__popup__content{
    display:flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    align-items: center;
    
    
}



@media only screen and (max-width: 768px) {
    
    
    .center__support__popup__content{
        display:flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        text-align: center;
    }



}