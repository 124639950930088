.match4{
    display:flex;
    align-content: center;
    align-items: center;
    width:100%;
    flex-direction: column;
}



.board {
  display: grid;
  justify-content: stretch;
  width: 100%;
  max-width: 500px;
  height: auto;
  padding:10px;
  background: var(--main);
  border-radius: 10px;
  aspect-ratio:1/1;
}
.drop-button {
  background-color: black;
  width: 100%;
  margin-bottom: 20px;


}

.cell {
  width: 100%;
  border-radius:100%;
}

.vertical__auto{
    display: flex;
    background-color: var(--dark__main);
    border-radius: 10px;
    flex-direction: column;
    height: auto;
    width: 50%;
    margin:2px;
    padding:10px;
}

.top__info__view
{
    width: 30%;
    display: flex;
    min-width: 600px;
    flex-direction: row;
    align-content: center;
    align-items: center;
    margin-top: 60px;
    justify-content: space-between;

}

@media only screen and (max-width: 768px) {

    .match4{
        display:flex;
        align-content: center;
        align-items: center;
        width:100%;
        flex-direction: column;
    }

    .board {
      display: grid;
      width: 100%;
      height: auto;
      aspect-ratio:1/1;
    }

    .drop-button {
      background-color: black;
      width: 100%;
      margin-bottom: 20px;
    }

    .cell {
      width: 100%;
      border-radius: 100%;
    }



    .top__info__view{
        width: 94%;
        display: flex;
        min-width: 94%;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: space-evenly;
    }


    .vertical__auto{
        background-color: var(--dark__main);
        border-radius: 10px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        padding:5px;
        height: auto;
        width: 50%;
    }
}
