@import url("Theme.css");

.request__table__container{
    display:flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-top:20px;
    
    width:100%;
}

.recent__cell__underline{
    border-bottom: 1px solid #aaa;
}

@media only screen and (max-width: 768px) {
    
    .request__table__container{
        display:flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        margin-top:20px;
        width:100%;
    }
    .recent__cell__underline{
        border-bottom: 1px solid #aaa;
    }
    
}