@import url("Theme.css");

.center__confirmation__popup__content{
    display:flex;
    flex-direction: column;
    text-align: center;
    width:100%;
    
    align-content: center;
    background-color: var(--main);
    align-items: center;
    
}


.horizontal__buttons{

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
    align-content: center;
    align-items: center;
    text-align: center;

}


@media only screen and (max-width: 768px) {
    
    
    .center__confirmation__popup__content{
        display:flex;
        width:100%;
        background-color: var(--main);
        flex-direction: column;
        align-content: center;
        align-items: center;
        text-align: center;
    }


    .horizontal__buttons{
        align-content: center;
        align-items: center;
        text-align: center;
        
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;

    }
    


}