@import url("Theme.css");


.settings {
    flex-direction: column;
    display:flex;
    align-items: center;
    
}


.settings__container {
    display: flex;
    flex-direction: column;
    text-align: start;
    width:50%;
    max-width: 600px;
    align-content: center;
    margin-top: 100px;
}




.balance__container{

    margin-bottom: 20px;
    
}



.who__sees__container{

    margin-bottom: 20px;
    
}
    
.email__container{
    
    border-bottom: 1px solid var(--gray);
    margin-bottom: 20px;
        
}
.top__container{
    margin-bottom: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    
}

.edit__email {
    justify-content: space-between;
    display: flex;
    align-items: center;
    align-content: center;
}
    
    
.edit__balance {
    justify-content: space-between;
    display: flex;
    align-items: center;
    align-content: center;
}

.edit__location {
    justify-content: space-between;
    display: flex;
    align-items: center;
    align-content: center;
}
    
.edit__who__sees {
    
    border-bottom: 1px solid var(--gray);
    justify-content: space-between;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    align-items: center;
    align-content: center;
}

.bottom__buttons__horizontal{
        justify-content: space-between;
        flex-direction: row;
        display: flex;
        align-content: center;
        align-items: center;
    
}

.bottom__buttons__vertical{
        margin-top: 50px;
        justify-content: space-between;
        flex-direction: column;
        display: flex;
        align-content: center;
        align-items: center;   
}

.location__container{
    border-bottom: 1px solid var(--gray);
}


.iframe__a{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
}

.center__terms__content{

    position: fixed;
    top:2vw;
    left:2vw;
    background-color: var(--dark__main);
    height: 98vw;
    width:96vw;
    
}

@media only screen and (max-width: 768px) {

    .settings{
        height:100vh;
        flex-direction: column;
        display:flex;
        align-items: center;
        padding-bottom: 100px;
    }
    .settings__container {
        display: flex;
        width:96vw;
        flex-direction: column;
        text-align: start;
        align-content: center;
        margin-top: 85px;
        padding-bottom: 100px;
        padding-left:2vw;
        padding-right:2vw;

    }
    
    .balance__container{
        border-bottom: 1px solid var(--gray);
        margin-bottom: 30px;
    }

    .who__sees__container{
        margin-bottom: 30px;
    }
    
    .email__container{

        border-bottom: 1px solid var(--gray);
        margin-bottom: 30px;
    }
    
    .top__container{
        justify-content: space-between;
        display: flex;
        margin-bottom: 50px;
        align-items: center;
        align-content: center;
    }
    
    
    .location__container{
        border-bottom: 1px solid var(--gray);
    }
    
    .edit__email {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        
    }
    
    
    .edit__balance {
        justify-content: space-between;
        display: flex;
        align-items: center;
        align-content: center;
    }
    
    .edit__who__sees {
        
        border-bottom: 1px solid var(--gray);
        width:100%;
        justify-content: space-between;
        display: flex;
        margin-top: 10px;
        margin-bottom: 10px;
        align-items: center;
        align-content: center;
    }
    
    
    .edit__location {
        justify-content: space-between;
        display: flex;
        align-items: center;
        align-content: center;
    }
    
    
    .bottom__buttons__horizontal{
        margin-top: 50px;
        justify-content: space-evenly;
        flex-direction: row;
        display: flex;
        align-content: center;
        align-items: center;
    
    }
    .bottom__buttons__vertical{

        justify-content: space-evenly;
        flex-direction: column;
        display: flex;
        align-content: center;
        align-items: center;
    
    }

    
}