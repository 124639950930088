@import url("Theme.css");



.history {
    flex-direction: column;
    display:flex;
    align-items: center;
    align-content: center;
}


.history__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    align-items: center;
    margin-top: 100px;
    width:80vw;
    justify-content: space-between;
}


.switch__layout {
    display: flex;
    font-size: 20px;
    align-items: stretch; 
    text-align: start;
    justify-content: space-between;
    border-radius: 10px;;
}


.fill__width__image {
    flex:1;
    width: 100%;
    padding-bottom: 20px;
}

.card__info__container {
    position: relative;
    width: auto;
    margin-bottom: 20px;
}
.card__size{
    font-size: 18px;
}

.card__description{
    font-size: 12px;
}

.square__image__start {
    flex:1;
    width: 50%;
    padding-bottom: 20px;
}


.cropped__image__display {
    position: relative;
    width: 90%;
    padding-bottom: 20px;
}


.cover__left__image {
    position: absolute;
    top: 0;
    left: 40px;
    width: 40%;
    height: 60%;
    background-color: white;
    z-index: 10;
}


.fill__width__image {
    flex:1;
}

.empty__list__text{
    color: white;
}


.fullscreen__image {
    flex:1;
    width: 100%;
    background-color: black;
    display:flex;
    justify-content:center; /* horizontally center */
    align-items:center;
}


.history__recent__games__container{
    margin-top: 50px;
    display:flex;
    width: 60%;
    align-items:center;
    align-content: center;
    text-align: center;
    justify-content:space-around; /* horizontally center */
    
}
.recent__games__header{
    margin-top: 50px;
    display:flex;
    width: 80%;
    flex-direction:row;
    justify-content:space-between; /* horizontally center */
    align-items:center;
    align-content: center;
    text-align: center;
}





@media only screen and (max-width: 768px) {

    .history{
        flex-direction: column;
        display:flex;
        align-items: center;
        align-content: center;
        padding-bottom: 100px;
    }

    .history__container {
        display: flex;
        flex-direction: column;
        text-align: start;
        align-content: center;
        margin-top: 50px;
        padding-bottom: 60px;
        padding-left:2vw;
        padding-right:2vw;
        width:96vw;
        
    }

    
    .cropped__image__display {
        position: relative;
        width: 90%;
        padding-bottom: 20px;
    }
    
    .cover__left__image {
        position: absolute;
        top: 0;
        left: 20px;
        width: 40%;
        height: 50%;
        background-color: white;
        z-index: 10;
    }
    .fill__width__image {
        flex:1;
    }
    
    
    .recent__games__header{
        
        margin-top: 20px;
        display:flex;
        width: 96vw;
        flex-direction:row;
        justify-content:space-between; /* horizontally center */
        align-items:center;
        align-content: center;
        text-align: center
    }    
    
    
    .history__recent__games__container{
        margin-top: 20px;
        display:flex;
        width: 96vw;
        justify-content:space-around; /* horizontally center */
        align-items:center;
        align-content: center;
        text-align: center;
    }
        



}

