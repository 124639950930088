@import url("Theme.css");

.search__bar__container{
    width:98%;
    margin-top:30px;
    margin-bottom:15px;
    padding:1%;
    border-radius: 5px;
    border-width: 0px;
    background-color:var(--dark__main);
    justify-content: space-between;
    flex-direction: row;
    display: flex;

}

.search__bar{
        font-size: 15px;
        border-radius: 5px;
        resize: none; 
        border-width: 0px;
        margin-right: 5px;
        width:100%
}


@media only screen and (max-width: 768px) {
    
    .search__bar__container{
        justify-content: space-between;
        flex-direction: row;
        display: flex;
        padding:1.5%;
        border-radius: 5px;
        border-width: 0px;
        width:97%;
        margin-top:15px;
        margin-bottom:15px;
        background-color:var(--dark__main);
    }
    
    
    .search__bar{
        font-size: 15px;
        border-radius: 5px;
        border-width: 0px;
        resize: none;
        margin-right: 5px;
        width:100%;
    }
    
}