
.center__handle__select__content{
    display:flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    align-items: center;
    
}


.handle__available{
    color: white;
    font-size: 24px;
}


.handle__not__available{
    color: yellow;
    font-size: 24px;

}

@media only screen and (max-width: 768px) {
    

    .center__handle__select__content{
        display:flex;
        flex-direction: column;
        text-align: center;
        align-content: center;
        align-items: center;


    }

    .handle__available{
        color: white;
        font-size: 24px;
    }


    .handle__not__available{
        color: yellow;
        font-size: 24px;

    }



}