.challenge__popup__image__container {
    vertical-align: middle;
    align-content: center;
    align-items: center;
    display: flex;
    width:20%;
    aspect-ratio: 1/1;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;

}

.challenge__popup__user__image{
    height: auto;
    aspect-ratio: 1/1;
    width: 100%;
    color:var(--text_main);
    overflow: hidden;
    align-content: center;
    align-items: center;
    border-radius: 5vw;
    border-color:white;
    border-style: solid;
}


.center__challenge__content{
    display:flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    align-items: center; 
}



.vertical__challenge__name__handle{
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    justify-content: space-evenly;
}

.user__handle__text{
    color:white;
    font-size: 16px;
    text-align: start;
}


.top__close__challenge__view{
    display: flex;
    flex-direction: row;
    width: 90%;
    align-content: center;
    align-items: center;
    margin-top:20px;
    margin-bottom:10px;
    justify-content: space-between;
}

.message__box{
    display: flex;
    flex-direction: column;
    width:90%;
    background-color: var(--dark__main);
    padding:10px;
    border-radius: 10px;
    border-width: 0px;
    overflow: hidden;
}
.name__text__profile{
    font-size: 18px;
    font-weight: bold;
    align-content: flex-start;
    align-items: flex-start;
    text-align: start;
    width: 40vw;
    color:var(--accent_yellow);
    margin-top:10px;
}

.horizontal__justify{
    display:flex;
    flex-direction:row;
    margin-top:20px;
    
}



.opponent__container{
    display: flex;
    width:90%;
    margin-top:20px;
    margin-bottom:20px;
    flex-direction: column;
    align-items: center;
    padding-bottom:5px;
    padding-right:5px;
    padding-left:5px;
    border-radius: 10px;
    background-color: var(--dark__main);
    align-content: center;
}
.direct__challenge__container{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-content: center;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
    margin-top: 10px;

}
.game__info__view{
    display:flex;
    flex-direction:row;
    width:90%;
    align-content: center;
    align-items: center;
    margin-bottom: 16px;
    padding:5px;
    border-radius: 15px;
    background-color: var(--dark__main);
    
}


.challenge__game__image {
    vertical-align: middle;
    align-content: center;
    align-items: center;
    display: flex;
    width:40%;
    aspect-ratio: 3/2;
    height: auto;
    border-radius: 15px;
    overflow: hidden;
    margin-right: 20px;
 
}

.challenge__game__image__constraint{
    height: auto;
    aspect-ratio: 3/2;
    width: 100%;
    
}

.turn__timer__container{
    display: flex;
    width:90%;
    padding:5px;
    border-radius: 10px;
    justify-content: space-between;
    background-color: var(--dark__main);
    flex-direction: row;
}

.bet__amount__container{
    display: flex;
    width:90%;
    margin-top: 20px;
    margin-top: 20px;
    padding:5px;
    border-radius: 10px;
    justify-content: space-between;
    background-color: var(--dark__main);
    flex-direction: row;
}




@media only screen and (max-width: 768px) {
    
    .direct__challenge__container{
        display: flex;
        flex-direction: row;
        width: 100%;
        border-radius: 10px;
        background-color: var(--dark__main);
        align-content: center;
        align-items: center;
        vertical-align: middle;
        justify-content: space-between;

    }
    
    .opponent__container{
        border-radius: 10px;
        background-color: var(--dark__main);
        display: flex;
        width:90%;
        padding-right:5px;
        padding-left:5px;
        margin-bottom:10px;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
    
    
    .vertical__challenge__name__handle{
        display: flex;
        margin-left: 10px;
        flex-direction: column;
        justify-content: space-around;

    }
    
    .game__info__view{
        display:flex;
        flex-direction:row;
        width:90%;
        padding:5px;
        border-radius: 10px;
        background-color: var(--dark__main);
        align-content: center;
        align-items: center;
        margin-bottom: 16px;
    }
    

    
    
    .turn__timer__container{
        display: flex;
        width:90%;
        padding:5px;
        border-radius: 10px;
        justify-content: space-between;
        background-color: var(--dark__main);
        flex-direction: row;
    }

    .bet__amount__container{
        display: flex;
        width:90%;
        margin-top: 20px;
        margin-top: 20px;
        padding:5px;
        border-radius: 10px;
        justify-content: space-between;
         background-color: var(--dark__main);
        flex-direction: row;
    }
    
    .challenge__game__image__constraint{
        height: auto;
        aspect-ratio: 3/2;
        width: 100%;
    }
    
    .challenge__game__image {
        vertical-align: middle;
        align-content: center;
        align-items: center;
        display: flex;
        width:35%;
        aspect-ratio: 3/2;
        height: auto;
        border-radius: 15px;
        margin-right:16px;
        overflow: hidden;
    }
    
    .top__close__challenge__view{
        display: flex;
        flex-direction: row;
        width: 90%;
        align-content: center;
        margin: 0px;
        align-items: center;
        justify-content: space-between;
    }
    
    .center__challenge__content{
        display:flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        text-align: center;
    }
    
    .challenge__popup__image__container {
        vertical-align: middle;
        align-content: center;
        display: flex;
        height: auto;
        aspect-ratio: 1/1;
        width: 20%;
        margin-right: 10px;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .challenge__popup__user__image{
        border-radius: 50%;
        align-content: center;
        border-width: 2px;
        height: auto;
        aspect-ratio: 1/1;
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .user__handle__text{
        color:white;
        font-size: 14px;

    }
    
    .horizontal__justify{
        display:flex;
        margin-top:10px;
        flex-direction:row;
    }
    
    .name__text__challenge{
        font-size: 16px;
        font-weight: bold;
        color:var(--accent_yellow);
        margin-top:10px;
        align-content: flex-start;
        align-items: flex-start;
        text-align: start;
        width: 90vw;
        
    }
    
    
    .message__box{
        display: flex;
        flex-direction: column;
        width:90%;
        background-color: var(--dark__main);
        padding:10px;
        border-radius: 10px;
        border-width: 0px;
        overflow: hidden;
    }



}