@import url("Theme.css");


.splash {
    display:flex;
    
    flex-direction: column;
    align-content: center;
    align-items: center;
}


    
.splash__container {
    display:flex;
    position: fixed;
    flex-direction: column;
    border-radius: 30px;
    width: 50%;
    height:auto;
    padding:20px;
    align-content: center;
    align-items: center;
    margin-top:10%;
    
}

.splash__logo{
    width:30%;
    height:auto;
    aspect-ratio:1/1;
}


@media only screen and (max-width: 768px) {
    
    
    .splash__logo{
        width:30%;
        height:auto;
        aspect-ratio:1/1;
    }

    .splash__container {
        display:flex;
        position: fixed;
        flex-direction: column;
        border-radius: 30px;
        width: 90%;
        height:auto;
        padding:20px;
        margin-top:20%;
        
        align-content: center;
        align-items: center;

    }


}