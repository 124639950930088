@import url("Theme.css");

.profile__recent__profile__image{
    height:51px;
    width:51px;
    margin-top: 5px;
    cursor: pointer;
    
    
}
.profile__recent__profile__image__constraint{
    height:50px;
    width:50px;
    border:solid;
    border-color: white;
    border-width: 2px;
    border-radius: 35px;
    overflow-x: hidden;
    overflow-y: hidden;

}

.profile__recent__cell__container{
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    display:flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    border-bottom: 1px solid white;
    
}

.profile__user__info__container{
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    width: 40vw;
    align-content: center;
    align-items: center;
    text-align: center;
}
    

.profile__recap__text{
    margin-left: 10px;
    color:white;
    font-size: 18px;
}
.profile__opponent__name{
    color:white;
    margin-right: 10px;
    margin-top: 10px;
    text-align: end;
    font-size: 22px;
}
.profile__challenger__name{
    color:white;
    margin-left: 10px;
    text-align: start;
    font-size: 22px;
    margin-top: 10px;
    
}
    


@media only screen and (max-width: 768px) {
    
    .profile__recent__profile__image{
        height:30px;
        width:30px;
        border-color: white;
        border-width: 1px;
        border-radius: 25px;
    }
    .profile__recent__profile__image__constraint{
        height:30px;
        width:30px;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    
    .profile__recent__cell__container{
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 10px;
        display:flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        text-align: center;
        vertical-align: middle;
        border-bottom: 1px solid white;
        
    }
        
    .profile__user__info__container{
        display: flex;
        flex-direction: row;
        width: 90vw;
        vertical-align: middle;
        align-content: center;
        align-items: center;
        text-align: center;
    }
    
    .profile__recap__text{
        margin-left: 10px;
        color:white;
        font-size: 14px;
    }
    
    .profile__opponent__name{
        color:white;
        text-align: end;
        font-size: 14px;
    }
    .profile__challenger__name{
        color:white;
        text-align: start;
        font-size: 14px;
    }

    
}