
.center__friend__select__content{
    display:flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    align-items: center;
    width: 100%;
    
    
    
}


.all__friends__select__container{
    display:flex;
    align-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    flex-direction: column;
    
    
}

.friend__select__top__view{
    display:flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

}


@media only screen and (max-width: 768px) {
    

    .center__friend__select__content{
        display:flex;
        flex-direction: column;
        text-align: center;
        align-content: center;
        align-items: center;
        width: 100%;

    }


    .all__friends__select__container{
        display:flex;
        align-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        flex-direction: column;

    }

    .friend__select__top__view{
        display:flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

    }

    



}